import React, { FC, useState } from 'react'
import SandflyEditTemplate, { initialValues } from '@templates/Sandfly/Edit'
import { graphql, navigate, PageProps } from 'gatsby'
import useForm from '@hooks/useForm'
import { Cancel, Check, Refresh, Save } from '@mui/icons-material'
import { ButtonGroup } from '@mui/material'
import { Button } from 'gatsby-material-ui-components'
import useApi, { wasSuccess } from '@hooks/useApi'
import { ApiResponse, createSandfly } from '@utils/api'

const SandfliesAddPage: FC<
  PageProps<{ markdownRemark: any }, object, { template?: string }>
> = ({ location, data }) => {
  const state = location?.state || {}
  const { template = initialValues.sandfly } = state
  const [saved, setSaved] = useState(false)

  const form = useForm({
    initialValues: { sandfly: template }
  })

  const api = useApi<ApiResponse>({
    apiMethod: createSandfly,
    params: form.values.sandfly,
    requestOnMount: false
  })

  const handleReset = () => {
    form.setFieldValue('sandfly', template)
  }

  const hasChanged = form.values.sandfly !== template

  const handleSave = async () => {
    let data: string
    if (!hasChanged) return

    try {
      data = JSON.parse(form.values.sandfly)
    } catch (error) {
      api.enqueueSnackbar('Failed to save Sandfly. Invalid JSON.', {
        variant: 'error'
      })
      return
    }

    await api.makeRequest(data).then((res) => {
      if (wasSuccess(res)) {
        setSaved(true)
        navigate(`/sandflies/`)
        api.enqueueSnackbar('Sandfly successfully created.', {
          variant: 'success',
          action: (
            <Button
              variant="outlined"
              color="inherit"
              to={`/sandflies/edit/${data.name}/`}
            >
              View
            </Button>
          )
        })
      }
    })
  }

  return (
    <SandflyEditTemplate
      title="Add Custom Sandfly"
      form={form as any}
      maxWidth="md"
      loading={false}
      feature="custom_sandflies"
      documentation={data.markdownRemark}
      breadcrumbs={[
        {
          title: 'Sandflies',
          to: '/sandflies/'
        },
        {
          title: 'Add Sandfly'
        }
      ]}
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Scan Page Actions"
        >
          <Button
            disabled={!hasChanged || saved || api.loading}
            variant="contained"
            endIcon={saved ? <Check /> : <Save />}
            onClick={handleSave}
          >
            {saved ? 'Saved' : 'Save'}
          </Button>

          <Button
            disabled={!hasChanged || saved}
            endIcon={<Refresh />}
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            disabled={saved}
            endIcon={<Cancel />}
            onClick={() => window !== undefined && window.history.back()}
          >
            Cancel
          </Button>
        </ButtonGroup>
      }
    />
  )
}

export const query = graphql`
  {
    markdownRemark(
      frontmatter: { slug: { eq: "custom-sandflies-operation" } }
    ) {
      ...SandflyDocumentation
    }
  }
`

export default SandfliesAddPage
